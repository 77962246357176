<template>


	<div id="indicatortools" class="p-2">

		{{ params }}

		<div class="mb-3">
		  <label for="exampleFormControlInput1" class="form-label">Indicator url</label>
		  <input type="email" class="form-control" id="exampleFormControlInput1" v-model="url" @input="getimage">
		</div>

	</div>



</template>
<script type="text/javascript">
	
</script>

<script>

	import axios from 'axios'


	export default{
		name:'indicatortools',
		 data(){
		     return{
		     	pages:[],
		     	url:"",
		     	params:{}
		     }
		 },
		mounted() {

			/*let self = this

			axios.get( 'http://localhost:8081/Tools/Getalllinks' ).then( function( response ){

				console.log( response )
				self.pages = response.data.links

			})*/
		},
		 methods:{
		 	getimage(){

		 		let self = this

		 		let iid = ""

		 		var search = this.url//location.search.substring(1);

				JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) })

				this.params = search

				axios.get( 'http://localhost:8081/Tools/Masterplanimagefromindicator?iid=' + iid ).then( function( response ){

					console.log( response )
					self.pages = response.data.links

				})

		 	}
		 }
	}
</script>